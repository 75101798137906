import React from 'react'

//https://github.com/treyhuffine/lightbox-react/blob/master/src/lightbox-react.js
//https://reactjsexample.com/lightbox-for-components-or-images-built-for-react/
import LightboxReact from 'lightbox-react'
import 'lightbox-react/style.css'
import Img from 'gatsby-image'

const NonStretchedImage = props => {
  let normalizedProps = props
  if (props.fluid && props.fluid.presentationWidth) {
    normalizedProps = {
      ...props,
      style: {
        ...(props.style || {}),
        maxWidth: props.fluid.presentationWidth,
        height: window.innerHeight - 50,
        width: "auto",
        margin: "50px auto 0",
      },
    }
  }

  return <Img {...normalizedProps} />
}

const Lightbox = ({
  images,
  selectedImage,
  handleClose,
  handlePrevRequest,
  handleNextRequest,
}) => {
  const array = []

  images.forEach(image =>
    array.push(<NonStretchedImage fluid={image.node.childImageSharp.fluid} />)
  )

  return (
    <div class="w-full h-full">
      <LightboxReact
        enableZoom={false}
        clickOutsideToClose={true}
        mainSrc={array[selectedImage]}
        nextSrc={array[(selectedImage + 1) % array.length]}
        prevSrc={array[(selectedImage + array.length - 1) % images.length]}
        onCloseRequest={handleClose}
        onMovePrevRequest={handlePrevRequest(selectedImage, array.length)}
        onMoveNextRequest={handleNextRequest(selectedImage, array.length)}
      />
    </div>
  )
}

export default Lightbox
