import React, { useState } from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Img from 'gatsby-image'
import Lightbox from '../components/lightbox'

export const query = graphql`
  query {
    bannerImage: file(relativePath: { eq: "pictures/haus.jpg" }) {
      ...sliderImage
    }
    galleryImages: allFile(filter: { relativeDirectory: { eq: "pictures/haus" } }) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 70) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    galleryImagesFullScreen: allFile(filter: { relativeDirectory: { eq: "pictures/haus" } }) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    
  }
`

export default function Gallery({ data }) {
  const [showLightbox, setShowLightbox] = useState(false)
  const [selectedImage, setSelectedImage] = useState(null)

  const handleOpen = i => e => {
    setShowLightbox(true)
    setSelectedImage(i)
  }
  const handleClose = () => {
    setShowLightbox(false)
    setSelectedImage(null)
  }
  const handlePrevRequest = (i, length) => e => {
    setSelectedImage((i - 1 + length) % length)
  }
  const handleNextRequest = (i, length) => e => {
    setSelectedImage((i + 1) % length)
  }

  return (
    <Layout title="Galerie" backgroundImage={data.bannerImage.childImageSharp.fluid}>
      <p class="self-start text-4xl font-light">Galerie</p>
      <div class="w-full
                  flex flex-wrap flex-col md:flex-row
                  items-between
                  justify-center
      ">
        { 
          data.galleryImages.edges.map(({ node }, index) => (
            <div class="w-full h-full md:w-1/3 py-5 md:px-5 cursor-pointer" role="presentation" onClick={handleOpen(index)} onKeyDown={handleOpen(index)}>
              <Img className="rounded" key={index} fluid={node.childImageSharp.fluid} />
            </div>
          ))
        }
      </div>
      {
        showLightbox && selectedImage !== null && (
          <Lightbox
            images={data.galleryImagesFullScreen.edges}
            handleClose={handleClose}
            handleNextRequest={handleNextRequest}
            handlePrevRequest={handlePrevRequest}
            selectedImage={selectedImage}
          />
        )
      }
    </Layout>
  )
}
